import React from "react";
import "./AboutUs.css"; // Import the updated CSS file
import GreenEarth from "../../images/greenearth.svg";
import GreenMission from "../../images/greenmission.svg";
import AboutUsImage from "../../images/blacklogo.svg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          About Us - BIGDOON: Sustainable Outdoor Marketing Solutions
        </title>
        <meta
          name="description"
          content="Discover BIGDOON, a leader in sustainable outdoor marketing in India. Learn about our mission to eliminate plastic waste and create innovative marketing solutions. Join us in championing a plastic-free future."
        />
        <meta
          name="keywords"
          content="About BIGDOON, sustainable outdoor marketing, eco-friendly marketing solutions, plastic-free India, innovative marketing strategies, environmental sustainability, outdoor advertising"
        />
        <meta name="author" content="BIGDOON" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.yourwebsite.com/about-us" />
        <meta
          property="og:title"
          content="About Us - BIGDOON: Sustainable Outdoor Marketing Solutions"
        />
        <meta
          property="og:description"
          content="Join BIGDOON in transforming outdoor marketing while promoting environmental sustainability. Learn about our mission and vision for a plastic-free India."
        />
        <meta
          property="og:image"
          content="https://bigdoon.com/static/media/greenmission.2ef9d4cc5ef7e3560ce9bb286b783bdb.svg"
        />
        <meta
          property="og:url"
          content="https://bigdoon.com/about-us"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="About Us - BIGDOON: Sustainable Outdoor Marketing Solutions"
        />
        <meta
          name="twitter:description"
          content="Discover our commitment to eco-friendly marketing solutions at BIGDOON. Learn more about our mission to eliminate plastic waste."
        />
        <meta
          name="twitter:image"
          content="https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg"
        />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "BIGDOON",
              "url": "https://www.bigdoon.com",
              "logo": "https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg",
              "sameAs": [
                "https://www.facebook.com/profile.php?id=61566496836252",
                "https://www.instagram.com/bigdoonmedia?igsh=MWI4cnA0cjNvcWNhNw==",
                "https://x.com/bigdoonmedia"
              ],
              "description": "BIGDOON is a leader in sustainable outdoor marketing in India, dedicated to eliminating plastic waste and providing innovative marketing solutions. We aim to create a plastic-free future through eco-friendly practices.",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-6360523961",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "BIGDOON, Thapliyal Residencial,Khairigaun, Atakfarm Khairi, Selakui",
                "addressLocality": "Dehradun",
                "addressRegion": "Uttarakhand",
                "postalCode": "248011",
                "addressCountry": "India"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="about-us-container">
        <header className="about-us-header">
          <h1>
            We are the{" "}
            <span className="text-logo-black-background">BIGDOON</span>
          </h1>
          <div class="quote-container">
            <blockquote class="quote">
              Empowering Your Business Growth with{" "}
              <span className="quote-highlight">
                Innovative Outdoor Marketing,
              </span>{" "}
              While Championing a Plastic-Free India!
            </blockquote>
          </div>
        </header>
        <div className="about-us-container-body">
          <div className="about-us-column left-column">
            <img
              src={GreenMission}
              alt="Description of SVG"
              className="svg-image"
            />
            <h2 className="our-vision-heading">Our Mission</h2>
            <p className="our-vision-desc">
              We strive to elevate outdoor marketing to new heights by
              innovating strategies like paper bag and glass marketing. Our
              mission is to phase out plastic and introduce sustainable,
              creative mediums, ensuring our clients reach wider, more engaged
              audiences.
            </p>
          </div>
          <div className="about-us-column middle-column">
            <img
              src={AboutUsImage}
              alt="Description of SVG"
              className="svg-image"
            />
            <h2 className="about-us-heading">About Us</h2>
            <p className="our-vision-desc">
              At BIGDOON, we are revolutionizing outdoor marketing in India by
              pioneering sustainable advertising solutions. We are dedicated to
              eliminating plastic waste by replacing traditional plastic bags
              and glasses with innovative, eco-friendly paper alternatives. Our
              focus is on transforming how brands connect with their audiences,
              offering creative and impactful marketing strategies that resonate
              with the modern, environmentally-conscious consumer. We are
              committed to leading the way in outdoor marketing, helping our
              clients achieve meaningful engagement while supporting a
              plastic-free future. Join us in making a significant impact on
              both the industry and the environment.
            </p>
          </div>
          <div className="about-us-column right-column">
            <img
              src={GreenEarth}
              alt="Description of SVG"
              className="svg-image"
            />
            <h2 className="our-vision-heading">Our Vision</h2>
            <p className="our-vision-desc">
              To create a vibrant, plastic-free India by merging outdoor
              marketing excellence with environmental action. We remove
              polythene from our ecosystem while planting trees to foster a
              cleaner, healthier earth.
            </p>
          </div>
        </div>
        <div className="about-us-contact-div">
          <h2>HAVE A QUESTION</h2>
        </div>
        <div className="about-us-contact-div-content">
          <p>Let's TALK</p>
          <button
            type="submit"
            className="about-us-contact-button"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
