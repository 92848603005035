import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './EditorHeading.css';
import { Helmet } from 'react-helmet';

const EditorHeading = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <>
        <Helmet>
        <title>Craft the Perfect Marketing Paper Bag | BIGDOON</title>
        <meta
          name="description"
          content="Design your brand's eco-friendly paper bags with BIGDOON. Elevate your marketing strategy with custom, environmentally-friendly solutions."
        />
        <meta
          name="keywords"
          content="design paper bags, custom marketing bags, eco-friendly paper bags, marketing strategy, brand message, BIGDOON"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.bigdoon.com/" />
        <meta
          property="og:title"
          content="Craft the Perfect Marketing Paper Bag | BIGDOON"
        />
        <meta
          property="og:description"
          content="Create eco-friendly, custom paper bags that deliver your brand message while supporting sustainability."
        />
        <meta property="og:url" content="https://www.bigdoon.com/" />
        <meta
          property="og:image"
          content="https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg" 
        />
      </Helmet>
        <div className={`editor-heading-container ${isMobile ? 'mobile' : ''}`}>
            <h2 className="editor-heading">Craft the Perfect Marketing Paper Bag</h2>
            <p className="editor-description">
                Design paper bags that don’t just carry products but also carry your brand’s message.
            </p>
        </div>
        </>
    );
};

export default EditorHeading;
