import React, { useState, useEffect } from 'react';
import { auth } from '../firebase'; // Adjust the path as necessary
import { useAuth } from '../context/AuthContext';
import './TrackOrder.css'; // Import CSS for styling


// Helper function to format the keys
const formatKey = (key) => {
  const formatted = key.replace(/([A-Z])/g, ' $1').trim();
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

const TrackOrder = () => {
  const [user, setUser] = useState(null);
  const { logout } = useAuth();
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [streetOptions, setStreetOptions] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const [orderData, setOrderData] = useState([]);
  const [savedTemplates, setSavedTemplates] = useState([]);
  const [activeTab, setActiveTab] = useState('orders'); // Track active tab
  const [selectedTemplate, setSelectedTemplate] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSelectTemplate = (event) => {
    const selectedId = event.target.value;
    const template = savedTemplates.find(t => t._id === selectedId);
    setSelectedTemplate(template);
  };

  useEffect(() => {
    // Fetch user details from Firebase Authentication
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser({
        email: currentUser.email,
        displayName: currentUser.displayName,
        photoURL: currentUser.photoURL,
      });

      // Fetch orders for the user from the API
      const fetchOrders = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/orders/${currentUser.uid}`);
          const data = await response.json();
          setOrderData(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      };

      fetchOrders();
    }
  }, []);

  const fetchTemplates = async () => {
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    try {
      const idToken = await user.getIdToken(); // Get the ID token

      const response = await fetch(`${apiUrl}/api/templates`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`, // Include ID token in Authorization header
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch templates');
      }

      const templates = await response.json();
      setSavedTemplates(templates);
      return templates;
    } catch (error) {
      console.error('Error fetching templates:', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  // Function to get the first name from displayName
  const getFirstName = (name) => {
    if (name) {
      return name.split(' ')[0]; // Get the first part of the name
    }
    return 'User';
  };

  useEffect(() => {
    // Update street options when an order is selected
    const selectedOrder = orderData.find((order) => order.order_id === selectedOrderId);
    if (selectedOrder) {
      setStreetOptions(selectedOrder.order_details.shipping_addresses);
    } else {
      setStreetOptions([]);
    }
  }, [selectedOrderId, orderData]);

  // Filter order events based on selectedAddressId
  const getOrderEvents = () => {
    const selectedOrder = orderData.find((order) => order.order_id === selectedOrderId);
    if (selectedOrder) {
      const addressHistory = selectedOrder.order_history.find((history) => history.address_id === selectedAddressId);
      return addressHistory ? addressHistory.events : [];
    }
    return [];
  };

  return (
    <div className="track-order-container">
      <div className="track-order-left-side">
        <div className="track-order-user-info">
          {user ? (
            <div className="track-order-user-details">
              {user.photoURL ? (
                <img src={user.photoURL} alt="User Avatar" className="track-order-user-avatar" />
              ) : (
                <div className="track-order-user-initial">
                  {getFirstName(user.displayName).charAt(0).toUpperCase()}
                </div>
              )}
              <div className="track-order-user-text">
                <h2>Welcome, {user.displayName || 'User'}!</h2>
                <p>Email: {user.email}</p>
                <button onClick={logout} className="track-order-logout-btn">Logout</button>
              </div>
            </div>
          ) : (
            <p>Loading user details...</p>
          )}
        </div>

        <nav className="track-order-menu">
          <ul className="track-order-menu-list">
            <li>
              <a
                href="#"
                className={`track-order-menu-item ${activeTab === 'orders' ? 'active' : ''}`}
                onClick={() => setActiveTab('orders')}
              >
                Track Order
              </a>
            </li>
            <li>
              <a
                href="#"
                className={`track-order-menu-item ${activeTab === 'templates' ? 'active' : ''}`}
                onClick={() => setActiveTab('templates')}
              >
                Saved Templates
              </a>
            </li>
          </ul>
        </nav>

        {activeTab === 'orders' && (
          <div className="track-order-details">
            {orderData &&
              orderData.length > 0 &&
              orderData.filter((order) => order.order_id === selectedOrderId).map((order) => (
                <div key={order.order_id} className="track-order-card">
                  <h3>Order ID: {order.order_id}</h3>
                  <p>Tracking Number: <b>{order.tracking_number}</b></p>
                  <p>Product Name: <b>{order.order_details.product_name}</b></p>
                  <p>Status: {order.status}</p>
                  <p>Estimated Delivery Date: <b>{order.estimated_delivery_date}</b></p>
                  <p>Total Price: <b>₹{order.order_details.total_price}</b></p>
                  <p><b>Locations:</b></p>
                  {order.order_details.shipping_addresses.map((addresses, index) => (
                    <p key={index}>
                      <b>{index + 1}. </b>
                      {addresses.street + ', ' + addresses.city + ', ' + addresses.country + ' (' + addresses.areaCode + ')'}
                    </p>
                  ))}
                </div>
              ))}
          </div>
        )}
      </div>

      {activeTab === 'orders' && (<div className="track-order-right-side">
        <h1>Track Order</h1>

        <div className="select-order">
          <div className="order-dropdown">
            <label htmlFor="order-id">Choose Ad name:</label>
            <select
              id="order-id"
              value={selectedOrderId}
              onChange={(e) => setSelectedOrderId(e.target.value)}
            >
              <option value="" disabled>Select an Ad</option>
              {orderData.length > 0 && orderData.map((order) => (
                <option key={order.order_id} value={order.order_id}>
                  {order.adsName}
                </option>
              ))}
            </select>
          </div>
          <div className="street-dropdown">
            <label htmlFor="street">Choose Street:</label>
            <select
              id="street"
              value={selectedAddressId}
              onChange={(e) => setSelectedAddressId(e.target.value)}
            >
              <option value="" disabled>Select a Street</option>
              {streetOptions.map((address, index) => (
                <option key={index} value={address.address_id}>
                  {address.street + ', ' + address.city}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="order-events">
          {getOrderEvents().map((event, index) => (
            <div key={index} className="order-event">
              <img src={event.delivery_photo} alt={`Delivery photo ${index + 1}`} className="order-event-photo" />
              <div className="order-event-details">
                <p><b>Shop Name:</b> {event.shop_name}</p>
                <p><b>Shop Mobile:</b> {event.shop_mobile}</p>
                <p><b>Quantity:</b> {event.quantity}</p>
                <p><b>Status:</b> {event.status}</p>
                <p><b>Timestamp:</b> {new Date(event.timestamp).toLocaleString()}</p>
              </div>
            </div>
          ))}
        </div>
      </div>)}

      {activeTab === 'templates' && (<div className="track-order-right-side">
      <div className="template-container">
      <div className="template-selector">
        <label htmlFor="template-select">Select Template:</label>
        <select id="template-select" onChange={handleSelectTemplate}>
          <option value="">-- Select a Template --</option>
          {savedTemplates.map(template => (
            <option key={template._id} value={template._id}>
              {template.content?.adTemplateName || 'Unnamed Template'}
            </option>
          ))}
        </select>
      </div>

      {selectedTemplate && selectedTemplate.content && (
        <div className="template-details">
          <h2>{selectedTemplate.content.adTemplateName || 'No Title'}</h2>
          {selectedTemplate.content.logo && (
            <img
              src={selectedTemplate.content.logo || '/default-logo.png'}
              alt="Template Logo"
              className="template-logo"
            />
          )}
          <table className="template-details-table">
            <tbody>
              {Object.entries(selectedTemplate.content).map(([key, value]) => (
                value && key !== 'logo' && (
                  <tr key={key}>
                    <td><strong>{formatKey(key)}:</strong></td>
                    <td>{value}</td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
      </div>)}
    </div>
  );
};

export default TrackOrder;
