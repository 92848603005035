import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FaExpand, FaCompress } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './TemplateEditor.css';
import bag from '../../images/bag.png';
import { auth } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define template configurations with different fields
const templateConfigs = {
  [bag]: {
    fields: [
      { label: 'Ad Template Name', type: 'text', placeholder: '', key: 'adTemplateName' },
      { label: 'Brand Name', type: 'text', placeholder: '', key: 'brandName' },
      { label: 'Brand Description', type: 'textarea', placeholder: '', key: 'brandDesc' },
      { label: 'Logo', type: 'file', placeholder: '', key: 'logo' }
    ]
  },
  // "https://images.stockcake.com/public/5/7/3/573501a7-c7f7-4822-b958-6c713a6a8c2b_large/dog-enjoys-park-stockcake.jpg": {
  //   fields: [
  //     { label: 'Ad Template Name', type: 'text', placeholder: '', key: 'adTemplateName' },
  //     { label: 'Brand Name', type: 'text', placeholder: '', key: 'brandName' },
  //     { label: 'Brand type', type: 'text', placeholder: '', key: 'brandType' },
  //     { label: 'Brand Description', type: 'textarea', placeholder: '', key: 'brandDesc' },
  //     { label: 'Logo', type: 'file', placeholder: '', key: 'logo' }
  //   ]
  // },
};

const templates = [
  bag,
  // "https://images.stockcake.com/public/5/7/3/573501a7-c7f7-4822-b958-6c713a6a8c2b_large/dog-enjoys-park-stockcake.jpg"
];

const TemplateEditor = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);
  const [formData, setFormData] = useState({});
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { isAuthenticated } = useAuth(); // Use auth context
  const navigate = useNavigate();

  const templateConfig = templateConfigs[selectedTemplate] || { fields: [] };
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleInputChange = (key, value) => {
    setFormData(prev => ({ ...prev, [key]: value }));
  };

  const handleFileChange = (key, e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the file is an image
      const fileType = file.type.split('/')[0];
      if (fileType === 'image') {
        setFormData(prev => ({ ...prev, [key]: URL.createObjectURL(file) }));
      } else {
        toast.error('Please upload a valid image file.');
      }
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const validateFormData = () => {
    for (const field of templateConfig.fields) {
      if (field.type !== 'file' && !formData[field.key]) {
        toast.error(`Please fill in the ${field.label} field.`);
        return false;
      }
      if (field.type === 'file' && !formData[field.key]) {
        toast.error(`Please upload the ${field.label}.`);
        return false;
      }
    }
    return true;
  };

  const handleSaveTemplate = async () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    if (!validateFormData()) return;

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User not authenticated');

      const idToken = await user.getIdToken();

      const response = await fetch(`${apiUrl}/api/templates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          user_id: user.uid,
          name: selectedTemplate,
          content: formData
        }),
      });

      if (response.ok) {
        toast.success('Template saved successfully!');
      } else {
        console.error('Failed to save template:', response.statusText);
        toast.error('Failed to save template.');
      }
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('Error saving template.');
    }
  };

  return (
    <div
      className={`template-editor-container ${isMobile ? 'mobile' : 'desktop'} ${isFullscreen ? 'fullscreen' : ''}`}
    >
      <div className="fullscreen-btn" onClick={toggleFullscreen}>
        {isFullscreen ? <FaCompress /> : <FaExpand />}
      </div>
      <div className="template-editor-left">
        <h2>Select Template</h2>
        {templates.map((template, index) => (
          <div key={index} className="template-container">
            <img
              src={template}
              alt={`Template ${index + 1}`}
              className={`template ${selectedTemplate === template ? 'selected' : ''}`}
              onClick={() => {
                setSelectedTemplate(template);
                setFormData({});
              }}
            />
          </div>
        ))}
      </div>

      <div className="template-editor-right">
        <div className="template-id">
          <strong>Selected Template ID:</strong> {selectedTemplate}
        </div>
        <form>
          {templateConfig.fields.map(field => (
            <div key={field.key} className="form-group">
              <label htmlFor={field.key}>{field.label}:</label>
              {field.type === 'text' ? (
                <input
                  type="text"
                  id={field.key}
                  value={formData[field.key] || ''}
                  placeholder={field.placeholder}
                  onChange={(e) => handleInputChange(field.key, e.target.value)}
                />
              ) : field.type === 'textarea' ? (
                <textarea
                  id={field.key}
                  value={formData[field.key] || ''}
                  placeholder={field.placeholder}
                  onChange={(e) => handleInputChange(field.key, e.target.value)}
                />
              ) : field.type === 'file' ? (
                <input
                  type="file"
                  id={field.key}
                  onChange={(e) => handleFileChange(field.key, e)}
                />
              ) : null}
            </div>
          ))}
          <button type="button" className='submit-template-btn' onClick={handleSaveTemplate}>Save Template</button>
          <p className='ad-form-details-desc'>
            At checkout, you can choose your preferred template by selecting the Ad Template Name. Once your order is placed, we will review it and get in touch with you to confirm and finalize your ad designs.
          </p>

        </form>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default TemplateEditor;
