import React, { useState } from 'react';
import './Login.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { auth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as Logo } from '../../images/blacklogo.svg';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [linkText, setLinkText] = useState('Forgot your password?');
    const [signText, setSignText] = useState('WANNA MAKE BRAND AWESOME');
    const [linkClass, setLinkClass] = useState('forgot-password-link');
    const [buttonClass, setButtonClass] = useState('login-button'); // Add buttonClass state
    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || '/';
    console.log('Redirecting from:', from);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleLogin = async (e) => {
        e.preventDefault();

        console.log('Attempting login with email:', email);

        try {
            // Sign in the user with email and password
            await signInWithEmailAndPassword(auth, email, password);

            // Check if the email is verified
            if (auth.currentUser && !auth.currentUser.emailVerified) {
                // If the email is not verified, sign the user out and show an error
                await auth.signOut();
                toast.error('Please verify your email before logging in.');
                return; // Prevent further actions
            }

            // If email is verified, proceed with login
            login(); // Call login from context
            toast.success('Login successful!');
            console.log('Navigating to:', from);
            navigate(from, { replace: true });

        } catch (error) {
            console.error('Login error:', error);
            if (error.code === 'auth/email-verification-required') {
                toast.error('Please verify your email before logging in.');
            } else {
                toast.error(error.message || 'Login failed!');
            }
        }
    };


    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        console.log('Attempting Google login with provider:', provider);

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const response = await fetch(`${apiUrl}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}` // Include the ID token if needed
                },
                body: JSON.stringify({
                    email: user.email,
                    displayName: user.displayName,
                    uid: user.uid,
                    companyName: 'Your Company Name' // You can set default or prompt for this
                }),
            });

            if (response.ok) {
                console.log('User data saved successfully!');
                login(); // Call login from context
                navigate(from, { replace: true });
                toast.success('Login successful with Google!');
            } else {
                console.error('Failed to save user data:', response.statusText);
                toast.error('Failed to save user data!');
            }
        } catch (error) {
            console.error('Google login error:', error);
            toast.error(error.message || 'Google login failed!');
        }
    };

    const handleMouseEnterLink = () => {
        setLinkText('Oops! No Issue BRO Just Reset Your password');
        setLinkClass('forgot-password-link hover'); // Example hover class for link
    };

    const handleMouseLeaveLink = () => {
        setLinkText('Forgot your password?');
        setLinkClass('forgot-password-link');
    };

    const handleMouseEnterButton = () => {
        setSignText(`Let's BUILD`);
        setButtonClass('login-button hover'); // Example hover class for button
    };

    const handleMouseLeaveButton = () => {
        setSignText('WANNA MAKE BRAND AWESOME');
        setButtonClass('login-button');
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <Logo className="svg-logo" />
                <h1>Let's EXPLORE</h1>
                <button className="social-login-button" onClick={handleGoogleLogin}>
                    <img
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt="Google Logo"
                        style={{ width: '20px', marginRight: '10px' }}
                    /> <span className='google-login-button-icon-gap'></span>Sign In with<strong style={{marginLeft: "5px"}}>GOOGLE</strong>
                </button>
                <p className="or-divider">or</p>
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <a
                        href="#"
                        className={linkClass}
                        onClick={() => navigate('/forgot-password')}
                        onMouseEnter={handleMouseEnterLink}
                        onMouseLeave={handleMouseLeaveLink}
                    >
                        {linkText}
                    </a>
                    <div className="terms-and-conditions">
                        <input type="checkbox" id="terms" required />
                        <label className='terms-consitions-label' htmlFor="terms">I accept the <a href="#">Terms and Conditions</a></label>
                    </div>
                    <button
                        className={buttonClass}
                        type="submit"
                        onMouseEnter={handleMouseEnterButton}
                        onMouseLeave={handleMouseLeaveButton}
                    >
                        {signText}
                    </button>
                </form>
                <p className="signup-link">Don't have an account? <a onClick={()=>navigate("/signup")}>Sign Up</a></p>
            </div>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default Login;
