import EditorHeading from './EditorHeading';
import './MainEditor.css';
import TemplateEditor from './TemplateEditor';

const CombinedComponent = () => (
  <div className="Main-combined-container">
    <EditorHeading />
    <TemplateEditor />
  </div>
);

export default CombinedComponent;
