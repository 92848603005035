import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../firebase"; // Adjust the path as needed
import {
  FaUser,
  FaEnvelope,
  FaCreditCard,
  FaPlus,
  FaPhoneAlt,
} from "react-icons/fa";
import { v4 as uuidv4 } from "uuid"; // Import UUID package
import "./CheckoutPage.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { RiAdvertisementFill } from "react-icons/ri";
import { HiLocationMarker, HiTemplate } from "react-icons/hi";
import { BsChatSquareText } from "react-icons/bs";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [plan, setPlan] = useState(null);
  const [locations, setLocations] = useState([
    {
      address_id: uuidv4(),
      street: "",
      city: "Dehradun",
      country: "India",
      areaCode: "",
    },
  ]);
  const [maxLocations, setMaxLocations] = useState(1); // Set your maximum number of locations here
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState(0);
  const [userId, setUserId] = useState(""); // Set the user ID as needed
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null); // To keep track of the selected template

  // New state variables for form fields with dummy values
  const [fullName, setFullName] = useState("");
  const [adsName, setAdsName] = useState("");
  const [adsInstruction, setAdsInstruction] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const initiatePayment = async () => {
    try {
      // Step 1: Get order details from your backend
      const response = await axios.post(`${apiUrl}/payment/create-order`, {
        price,
        currency: "INR",
        receipt: `receipt_${Date.now()}`, //generate unique receipt IDs
        productName,
        user_id: userId,
        phone,
        fullName,
        email,
        tempId: uuidv4(), // Generate a unique tempId
        adsName,
        adsInstruction,
        paymentMethod,
        locations,
        selectedTemplate,
      });

      // Step 2: Open Razorpay payment popup
      const { order } = response.data;

      const options = {
        key: "rzp_test_JpFSDrVjBMCZhN",
        amount: order.amount,
        currency: "INR",
        name: "BIGDOON",
        description: adsName,
        image:
          "http://localhost:3000/static/media/logo.dca476c95184560e6a5e80e14d69ffe6.svg",
        order_id: order.id, // Razorpay order ID
        handler: function (response) {
          axios
            .post(`${apiUrl}/payment/verify`, {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            })
            .then((res) => {
              navigate("/payment/success");
              console.log("Payment verification success:", res.data);
            })
            .catch((err) => {
              navigate("/payment/failure");
              console.error("Payment verification error:", err);
            });
        },
        prefill: {
          name: fullName,
          email: email,
          contact: phone,
        },
        theme: {
          color: "#0C831F",
        },
      };

      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.error("Error initiating Razorpay payment:", error.message);
      toast.error("Payment initiation failed");
    }
  };

  useEffect(() => {
    if (user) {
      setUserId(user.uid);
      setFullName(user.displayName);
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    const fetchTemplates = async () => {
      const user = auth.currentUser;

      if (!user) {
        throw new Error("User not authenticated");
      }

      try {
        const idToken = await user.getIdToken(); // Get the ID token

        const response = await fetch(`${apiUrl}/api/templates`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`, // Include ID token in Authorization header
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch templates");
        }

        const templates = await response.json();
        setTemplates(templates); // Store templates in state
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchTemplates();
  }, [apiUrl]);

  // useEffect(() => {
  //   console.log("templates", templates);
  //   console.log("selected template", selectedTemplate)
  // }, [templates])

  // useEffect(() => {
  //   console.log("plan", plan);
  // }, [plan])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (!currentUser) {
        navigate("/login"); // Redirect to login if not authenticated
      } else {
        setUser(currentUser);

        // Retrieve plan details from local storage
        const planData = JSON.parse(localStorage.getItem("selectedPlan"));
        if (planData) {
          setPlan(planData);
          setPrice(planData.price);
          setMaxLocations(planData.maxLocations || 1);
          setProductName(planData.name);
        } else {
          // Handle case when there is no plan data in local storage
          setPlan(null);
          setPrice("");
          setMaxLocations(1);
          setProductName("");
        }
      }
    });

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, [navigate]);

  if (!user) {
    return <p>Loading...</p>;
  }

  // Function to handle adding a new location
  const addLocation = () => {
    if (locations.length < maxLocations) {
      setLocations([
        ...locations,
        {
          address_id: uuidv4(),
          street: "",
          city: "",
          country: "",
          areaCode: "",
        },
      ]);
    } else {
      toast.error(`You can only add up to ${maxLocations} locations.`);
    }
  };

  // Function to handle location input change
  const handleLocationChange = (index, event) => {
    const { name, value } = event.target;
    const newLocations = [...locations];
    newLocations[index] = { ...newLocations[index], [name]: value };
    setLocations(newLocations);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the form is valid
    if (e.target.checkValidity()) {
      initiatePayment();
    } else {
      toast.error("Please fill out all required fields.");
    }
  };

  return (
    <div className="checkout-page-container">
      <div className="rocket-container">
        <div className="rocket">
          <div className="rocket-body"></div>
          <div className="rocket-fin"></div>
          <div className="rocket-fin right"></div>
          <div className="rocket-fire"></div>
          <div className="rocket-smoke"></div>
        </div>
      </div>
      <div className="checkout-page-left-side">
        {/* Pricing Card */}
        <div className="pricing-card pricing-card--featured">
          {plan.discount && (
            <div className="pricing-card__discount-label">{plan.discount}</div>
          )}
          <h2 className="pricing-card__title">{plan.name}</h2>
          <p className="pricing-card__price">
            ₹ <span className="plan-price-text">{plan.price}/-</span>
          </p>
          <ul className="pricing-card__list">
            {plan.details.map((item, index) => (
              <li className="pricing-card__list-item" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="checkout-page-right-side">
        <h1 className="checkout-page-right-side-heading">Almost There!</h1>
        <div className="checkout-form-container">
          <form className="checkout-form" onSubmit={handleSubmit}>
            {/* personal details */}
            <div className="form-group">
              <label htmlFor="full-name">
                <FaUser className="form-label-icon" /> Full Name
              </label>
              <input
                type="text"
                id="full-name"
                name="fullName"
                placeholder="Enter your full name here"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                <FaEnvelope className="form-label-icon" /> Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="john.doe@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">
                <FaPhoneAlt className="form-label-icon" /> Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your contact number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>

            {/* order details */}
            <div className="my-divider"></div>
            <div className="template-group">
              <label htmlFor="template" className="template-label">
                <span className="template-icon">
                  <HiTemplate />
                </span>
                Select Ad Template
              </label>
              <select
                id="template"
                className="template-select"
                value={selectedTemplate?._id || ""}
                onChange={(e) => {
                  const template = templates.find(
                    (t) => t._id === e.target.value
                  );
                  setSelectedTemplate(template);
                  setAdsName(template?.content?.adTemplateName || "");
                  setFullName(template?.content?.brandName || "");
                  setAdsInstruction(template?.content?.brandDesc || "");
                }}
                required
              >
                <option value="" disabled>
                  Select a Template
                </option>
                {templates.length > 0 &&
                  templates.map((template) => (
                    <option key={template._id} value={template._id}>
                      {template.content?.adTemplateName || "Unnamed Template"}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="ads-name">
                <RiAdvertisementFill className="form-label-icon" /> Ad Name
              </label>
              <input
                type="text"
                id="ads-name"
                name="adsName"
                placeholder="My Awesome Ad"
                value={adsName}
                onChange={(e) => setAdsName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="ads-instruction">
                <BsChatSquareText className="form-label-icon" /> Ad Instruction
              </label>
              <input
                type="text"
                id="ads-instruction"
                name="adsInstruction"
                placeholder="Please display this ad in all available stores."
                value={adsInstruction}
                onChange={(e) => setAdsInstruction(e.target.value)}
                required
              />
            </div>

            {/* Locations */}
            {locations.map((location, index) => (
              <div key={index} className="form-group">
                <label>
                  <HiLocationMarker className="form-label-icon" /> Ad location{" "}
                  {index + 1}
                </label>
                <input
                  type="text"
                  name="street"
                  placeholder="Street"
                  value={location.street}
                  onChange={(e) => handleLocationChange(index, e)}
                  required
                />
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={location.city}
                  onChange={(e) => handleLocationChange(index, e)}
                  required
                />
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={location.country}
                  onChange={(e) => handleLocationChange(index, e)}
                  required
                />
                <input
                  type="text"
                  name="areaCode"
                  placeholder="Area Code"
                  value={location.areaCode}
                  onChange={(e) => handleLocationChange(index, e)}
                  required
                />
              </div>
            ))}
            {locations.length < maxLocations && (
              <button
                type="button"
                className="add-location-button"
                onClick={addLocation}
              >
                <FaPlus className="form-label-icon" /> Click to add more
                locations
              </button>
            )}

            <div className="my-divider"></div>
            {/* payment method */}
            <div className="payment-method-group">
              <label htmlFor="payment-method" className="payment-method-label">
                <FaCreditCard className="payment-method-icon" /> Payment Method
              </label>
              <select
                id="payment-method"
                name="paymentMethod"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
                className="payment-method-select"
              >
                <option style={{ color: "black" }} value="" disabled>
                  Select Payment Method
                </option>
                <option value="upi">UPI</option>
                <option value="creditcard">Credit Card</option>
                <option value="debitcard">Debit Card</option>
                <option value="netbanking">Net Banking</option>
                <option value="wallet">Wallet</option>

                {/* Add more payment options if needed */}
              </select>
            </div>

            {/* Submit Button */}
            <button type="submit" className="submit-button">
              Confirm Your Purchase
            </button>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default CheckoutPage;
