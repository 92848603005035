import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu, IoBag } from "react-icons/io5"; // Import the icon
import { useMediaQuery } from "react-responsive";
import "./NavbarHook.css"; // Ensure the CSS is imported
import Logo from '../images/blacklogo.svg';
import { FaCartArrowDown } from "react-icons/fa";

const NavbarHook = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: "765px" });

    const toggleMenu = () => {
        console.log("Toggle menu", !isMenuOpen); // Debugging statement
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMobileMenu = () => {
        if (isMobile) {
            setIsMenuOpen(false);
        }
    };

    const renderNavLinks = () => {
        const listClassName = isMobile ? "nav__list" : "nav__list__web";
        const linkClassName = "nav__link";
        const buttonClassName = "nav__cta";

        return (
            <ul className={listClassName}>
                <li>
                    <NavLink to="/" className={linkClassName} onClick={closeMobileMenu}>
                        HOME
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/pricing"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                    >
                        PRICING
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/about-us"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                    >
                        ABOUT US
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/contact"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                    >
                        CONTACT
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/career"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                    >
                        CAREER
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/track-order"
                        className={`${linkClassName} ${buttonClassName} nav__track-order`}
                        onClick={closeMobileMenu}
                        style={{ color: 'white' }}
                    >
                        <FaCartArrowDown style={{ marginRight: '8px', fontSize: '18px' }} />
                        TRACK ORDER
                    </NavLink>
                </li>

            </ul>
        );
    };

    return (
        <header className="header">
            <nav className="nav">
                <NavLink to="/" className="nav__logo">
                    <img src={Logo} alt="Logo" />
                </NavLink>

                {isMobile && (
                    <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                        <IoMenu />
                    </div>
                )}

                <div
                    className={`nav__menu ${isMenuOpen ? "show-menu" : ""}`}
                    id="nav-menu"
                >
                    {renderNavLinks()}
                    {isMobile && (
                        <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                            <IoClose />
                        </div>
                    )}
                </div>
            </nav>
        </header>
    );
};

export default NavbarHook;
