import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import "./Pricing.css";
import { Helmet } from "react-helmet";

const Pricing = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();

  // Handle plan selection
  const handlePlanClick = (selectedPlan) => {
    localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
    navigate("/checkout");
  };

  return (
    <div className={`pricing-container ${isMobile ? "mobile" : "desktop"}`}>
       <Helmet>
        <title>Pricing Plans | Outdoor Marketing Solutions</title>
        <meta name="description" content="Explore our pricing plans tailored for outdoor marketing needs. Choose the perfect plan to enhance your brand's visibility and engagement." />
        <meta name="keywords" content="pricing, outdoor marketing, bags, customization, marketing strategies" />
        <meta property="og:title" content="Pricing Plans - Outdoor Marketing Solutions" />
        <meta property="og:description" content="Discover our diverse pricing plans designed for outdoor marketing. Choose the right plan for your business needs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://bigdoon.com/pricing" />
        <meta property="og:image" content="https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg" />
      </Helmet>
      <header className="pricing-header">
        <h1 className="pricing-container__title">
          Choose Your{" "}
          <span className="text-perfect-plan-background">Perfect Plan</span>
        </h1>
        <p>Find the perfect plan for your business needs.</p>
      </header>
      <div className="pricing-container__grid">
        <div className="pricing-card">
          <h2 className="pricing-card__title">Starter</h2>
          <p className="pricing-card__price">
            ₹ <span className="plan-price-text">24999/-</span>
          </p>
          <ul className="pricing-card__list">
            <li className="pricing-card__list-item">2000 Bags</li>
            <li className="pricing-card__list-item">Fully Customization*</li>
            <li className="pricing-card__list-item">Covered one City</li>
            <li className="pricing-card__list-item">Covered max 20 shops minimum 100 bags/shop</li>
            <li className="pricing-card__list-item">Covered one Location</li>
            <li className="pricing-card__list-item">
              Minimum area covered per location around 2km
            </li>
            <li className="pricing-card__list-item">Track each moment</li>
            <li className="pricing-card__list-item">
              Full support on bag design
            </li>
            <li className="pricing-card__list-item">
              Full support on mobile
            </li>
          </ul>
          <button
            className="pricing-card__button"
            onClick={() =>
              handlePlanClick({
                name: "Starter",
                price: "24999",
                maxLocations: 1,
                details: [
                  "2000 Bags",
                  "Fully Customization*",
                  "Covered one City",
                  "Covered max 20 shops minimum 100 bags/shop",
                  "Covered one Location",
                  "Minimum area covered per location around 2km",
                  "Track each moment",
                  "Full support on bag design",
                  "Full support with mobile",
                ],
              })
            }
          >
            Get Started
          </button>
        </div>
        <div className="pricing-card pricing-card--featured">
          <div className="pricing-card__discount-label">25% OFF</div>
          <h2 className="pricing-card__title">Recommended</h2>
          <p className="pricing-card__price">
            ₹ <span className="plan-price-text">49999/-</span>
          </p>
          <ul className="pricing-card__list">
            <li className="pricing-card__list-item">5000 Bags</li>
            <li className="pricing-card__list-item">Fully Customization*</li>
            <li className="pricing-card__list-item">Covered one City</li>
            <li className="pricing-card__list-item">Covered max 50 shops minimum 100 bags/shop</li>
            <li className="pricing-card__list-item">Covered max 4 Locations</li>
            <li className="pricing-card__list-item">
              Minimum area covered per location around 2km
            </li>
            <li className="pricing-card__list-item">Track each moment</li>
            <li className="pricing-card__list-item">
              Full support on bag design
            </li>
            <li className="pricing-card__list-item">
              Full support with mobile
            </li>
          </ul>
          <button
            className="pricing-card__button"
            onClick={() =>
              handlePlanClick({
                name: "Recommended",
                price: "49999",
                discount: "25% OFF",
                maxLocations: 4,
                details: [
                  "5000 Bags",
                  "Fully Customization*",
                  "Covered one City",
                  "Covered max 50 shops minimum 100 bags/shop",
                  "Covered max 4 Locations",
                  "Minimum area covered per location around 2km",
                  "Track each moment",
                  "Full support on bag design",
                  "Full support with mobile",
                ],
              })
            }
          >
            Get Started
          </button>
        </div>
        <div className="pricing-card">
          <h2 className="pricing-card__title">Customizable</h2>
          <p className="pricing-card__price">
            <span className="plan-price-text">Unlimited</span>
          </p>
          <ul className="pricing-card__list">
            <li className="pricing-card__list-item">Unlimited Bags</li>
            <li className="pricing-card__list-item">Fully Customization*</li>
            <li className="pricing-card__list-item">Covered only one City</li>
            <li className="pricing-card__list-item">Covered ulimited shops minimum 100 bags/shop</li>
            <li className="pricing-card__list-item">
              Covered Unlimited* Locations
            </li>
            <li className="pricing-card__list-item">
              Minimum area covered per location around 2km
            </li>
            <li className="pricing-card__list-item">Track each moment</li>
            <li className="pricing-card__list-item">
              Full support on bag design
            </li>
            <li className="pricing-card__list-item">
              Full support with mobile
            </li>
            <li className="pricing-card__list-item">
              Dedicated marketing manager
            </li>
          </ul>
          <button
            className="pricing-card__button"
            onClick={() => navigate("/contact")}
          >
            Contact Sales
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
