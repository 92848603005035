import React from 'react';
import './PaymentFailure.css';
import { useNavigate } from "react-router-dom";
import { RxCross2 } from 'react-icons/rx';

const PaymentFailure = () => {
  const navigate = useNavigate();

  return (
    <div className="payment-failure-main-container">
    <div className="payment-failure-container">
      <div className="payment-failure-icon">
        <RxCross2 />
      </div>
      <h1 className="payment-failure-heading">Payment Failed</h1>
      <p className="payment-failure-message">We encountered an issue while processing your payment. Please try again.</p>
      <button className="payment-failure-button" onClick={()=> navigate("/pricing")}>Retry Payment</button>
    </div>
    </div>
  );
};

export default PaymentFailure;
