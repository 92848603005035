import React, { useState } from 'react';
import { auth, sendPasswordResetEmail } from '../../firebase'; // Import Firebase
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ForgotPassword.css'; // Add styles if needed
import { ReactComponent as Logo } from '../../images/blacklogo.svg';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(
        <>
          If the email address is associated with an account, a password reset link has been sent. Please check your inbox.
          <br />
          <br />
          Redirecting to login page...
        </>
      );
      setEmail('');

      setTimeout(() => {
        navigate('/login');
      }, 5000);

    } catch (error) {
      console.error('Error sending password reset email:', error);

      // Firebase error codes can be found here: https://firebase.google.com/docs/auth/admin/errors
      let errorMessage = 'Failed to send password reset email.';

      switch (error.code) {
        case 'auth/invalid-email':
          errorMessage = 'The email address is badly formatted.';
          break;
        case 'auth/user-not-found':
          errorMessage = 'No user found with this email address.';
          break;
        case 'auth/missing-email':
          errorMessage = 'Please enter your email address.';
          break;
        default:
          errorMessage = error.message || errorMessage;
          break;
      }

      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form">
        <Logo className="svg-logo" />
        <h1>Forgot Password?</h1>
        <p>No worries! we'll send you reset instructions.</p>
        <form onSubmit={handlePasswordReset}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Reset password'}
          </button>
          <div className='back-to-login' onClick={()=> navigate("/login")}>
            <FiArrowLeft className='back-icon' />
            <span>Back to log in</span>
          </div>
        </form>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default ForgotPassword;
