import React from "react";
import TopHeading from "./components/TopHeading";
import CombinedComponent from "../TemplateEditor/MainEditor";
import HowItWorks from "./components/HowItWorks";

const Home = () => {
  return (
    <div>
      <div className="top-heading">
        <TopHeading />
        {/* <SecondComponent /> */}
        <CombinedComponent />
        <HowItWorks />
      </div>
    </div>
  );
};

export default Home;