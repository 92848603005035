import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './Signup.css';
import { ReactComponent as Logo } from '../../images/blacklogo.svg';
import { auth, GoogleAuthProvider, signInWithPopup } from '../../firebase';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const navigate = useNavigate();
  const [signText, setSignText] = useState('WANNA MAKE BRAND AWESOME');
  const [buttonClass, setButtonClass] = useState('login-button'); // Add buttonClass state

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    try {
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Update user profile
      await updateProfile(user, {
        displayName: displayName,
      });

      // Send email verification
      await sendEmailVerification(user);

      // Call backend to store additional data
      const response = await fetch(`${apiUrl}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          displayName,
          companyName,
          uid: user.uid,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message);
        // Redirect to login page after a successful signup
        setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
      } else {
        throw new Error(result.error);
      }
    } catch (err) {
      toast.error(
        <>
          {err.message}
          <br />
          <br />
          Action required: If you're already registered and have forgotten your password, simply reset it to regain access!
        </>,
        { autoClose: false }
      );
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    console.log('Attempting Google login with provider:', provider);

    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;

        const response = await fetch(`${apiUrl}/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}` // Include the ID token if needed
            },
            body: JSON.stringify({
                email: user.email,
                displayName: user.displayName,
                uid: user.uid,
                companyName: 'Your Company Name' // You can set default or prompt for this
            }),
        });

        if (response.ok) {
            console.log('User data saved successfully!');
            // login(); // Call login from context
            navigate("/track-order");
            toast.success('Login successful with Google!');
        } else {
            console.error('Failed to save user data:', response.statusText);
            toast.error('Failed to save user data!');
        }
    } catch (error) {
        console.error('Google login error:', error);
        toast.error(error.message || 'Google login failed!');
    }
};


  const handleMouseEnterButton = () => {
    setSignText(`Let's MAKE IT TOGETHER`);
    setButtonClass('signup-button hover');
  };

  const handleMouseLeaveButton = () => {
    setSignText('WANNA MAKE BRAND AWESOME');
    setButtonClass('signup-button');
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <Logo className="svg-logo" />
        <h1 className="signup-heading">BE with Us. let's Signup</h1>
        <button className="social-login-button" onClick={handleGoogleLogin}>
          <img
            src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
            alt="Google Logo"
            style={{ width: '20px', marginRight: '10px' }}
          /> <span className='google-login-button-icon-gap'></span>Sign Up with <strong style={{marginLeft: "5px"}}>GOOGLE</strong>
        </button>
        <p className="or-divider">or</p>
        <form onSubmit={handleSubmit} className="signup-form-fields">
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup-input"
            required
          />
          <input
            type="text"
            placeholder="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="signup-input"
            required
          />
          <input
            type="text"
            placeholder="Full Name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            className="signup-input"
            required
          />
          <input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signup-input"
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="signup-input"
            required
          />
          <button
            className={buttonClass}
            type="submit"
            onMouseEnter={handleMouseEnterButton}
            onMouseLeave={handleMouseLeaveButton}
          >
            {signText}
          </button>
        </form>
        <p className="signin-link">Already have an account? <a onClick={() => navigate("/login")}>Sign In</a></p>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default Signup;
