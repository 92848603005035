import React, { useState, useEffect, useRef } from 'react';
import '../css/TypingEffect.css';

const TypingEffect = ({ textList, speed = 100, cursorBlinkSpeed = 400, cycleInterval = 7000 }) => {
    const [displayText, setDisplayText] = useState('');
    const [cursorVisible, setCursorVisible] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const textIndex = useRef(0);
    const typingInterval = useRef(null);
    const cursorInterval = useRef(null);

    useEffect(() => {
        // Typing effect
        typingInterval.current = setInterval(() => {
            if (textIndex.current < textList[currentIndex].text.length) {
                setDisplayText(prevText => prevText + textList[currentIndex].text.charAt(textIndex.current));
                textIndex.current++;
            } else {
                clearInterval(typingInterval.current);
            }
        }, speed);

        return () => clearInterval(typingInterval.current);
    }, [textList, currentIndex, speed]);

    useEffect(() => {
        // Cursor blinking effect
        cursorInterval.current = setInterval(() => {
            setCursorVisible(prev => !prev);
        }, cursorBlinkSpeed);

        return () => clearInterval(cursorInterval.current);
    }, [cursorBlinkSpeed]);

    useEffect(() => {
        // Cycle through texts
        const cycleIntervalId = setInterval(() => {
            setDisplayText(''); // Clear display text
            textIndex.current = 0; // Reset index
            setCurrentIndex((prevIndex) => (prevIndex + 1) % textList.length); // Cycle through textList
        }, cycleInterval);

        return () => clearInterval(cycleIntervalId);
    }, [textList, cycleInterval]);

    return (
        <span className='typing-effect'>
            <span style={{ color: textList[currentIndex].color }}>{displayText}</span>
            <span className={`cursor ${cursorVisible ? 'visible' : 'hidden'}`}>|</span>
        </span>
    );
};

export default TypingEffect;
