// src/App.js
import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavbarHook from "./NavbarHook/NavbarHook";
import Home from "./pages/home/Home";
import AboutUs from "./pages/aboutus/AboutUs";
import ContactUs from "./pages/contactus/ContactUs";
import Careers from "./pages/careers/Careers";
import Footer from "./Footer/Footer";
import Pricing from "./pages/pricing/Pricing";
import Login from "./auth/login/Login";
import Signup from "./auth/signup/Signup";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import TrackOrder from "./TrackOrder/TrackOrder";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import PaymentSuccess from "./pages/payment/PaymentSuccess";
import PaymentFailure from "./pages/payment/PaymentFailure";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import Disclaimers from "./pages/legal/Disclaimers";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import RefundPolicy from "./pages/legal/RefundPolicy";
import ShippingPolicy from "./pages/legal/ShippingPolicy";
import ScrollToTop from "./ScrollToTop";
import ForgotPassword from "./auth/forget-password/ForgotPassword";
import axios from "axios";

const App = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const pingServer = () => {
      axios
        .get(`${apiUrl}/ping`)
        .then((response) => {
          // console.log("Ping successful:", response.data);
        })
        .catch((error) => {
          // console.error(
          //   "Error pinging server:",
          //   error.response?.status || error.message
          // );
        });
    };

    // Call pingServer immediately on app start
    pingServer();

    // Set interval to call pingServer every 5 minutes
    const interval = setInterval(pingServer, 300000); // 300000 ms = 5 minutes

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [apiUrl]);

  return (
    <AuthProvider>
      <Router>
        <NavbarHook />
        <main className="main-content">
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/career" element={<Careers />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/disclaimers" element={<Disclaimers />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />
            <Route path="/payment/failure" element={<PaymentFailure />} />

            {/* Protected route example */}
            <Route
              path="/track-order"
              element={<ProtectedRoute element={() => <TrackOrder />} />}
            />
            <Route
              path="/checkout"
              element={<ProtectedRoute element={() => <CheckoutPage />} />}
            />
            {/* <Route
              path="/career"
              element={
                <ProtectedRoute
                  element={() => <Careers />}
                />
              }
            /> */}
          </Routes>
        </main>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
