import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  FaBullhorn,
  FaPaintBrush,
  FaStore,
  FaHandsHelping,
  FaChartLine,
  FaLeaf,
  FaRecycle,
  FaRocket,
} from "react-icons/fa";
import "../css/HowItWorks.css";
import { Helmet } from "react-helmet";

const HowItWorks = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const steps = [
    {
      icon: <FaBullhorn />,
      text: "<strong>We begin by getting to know your brand</strong>, target audience, and marketing objectives. This ensures that our custom paper bag designs effectively communicate your brand’s message and align with your business goals.",
    },
    {
      icon: <FaPaintBrush />,
      text: 'Our design team works closely with you to create <span style="color: #ff6347;"><strong>visually appealing and impactful paper bag designs</strong></span>. We focus on making your brand stand out with creative visuals that attract attention and leave a lasting impression on your customers.',
    },
    {
      icon: <FaStore />,
      text: 'We collaborate with a network of retail partners to <span style="color: #32cd32;"><strong>distribute your branded paper bags</strong></span>. These partners include popular stores, cafes, and other high-traffic locations, ensuring your brand reaches a wide and relevant audience.',
    },
    {
      icon: <FaHandsHelping />,
      text: 'Your custom-designed paper bags are <span style="color: #ffa500;"><strong>distributed for free</strong></span> at our partner locations, turning everyday customers into brand ambassadors. As they carry your branded bags, your message spreads organically, reaching even more potential customers.',
    },
    {
      icon: <FaChartLine />,
      text: '<span style="color: #4682b4;"><strong>Our strategic distribution</strong></span> ensures that your brand is seen by the right people. With your logo and message in hand, customers become more familiar with your brand, increasing brand recall and loyalty.',
    },
    {
      icon: <FaLeaf />,
      text: 'We monitor the success of your paper bag marketing campaign by tracking metrics such as <span style="color: #ff4500;"><strong>customer feedback</strong></span>, increased foot traffic, and brand awareness. Detailed reports provide you with clear insights into the campaign’s ROI.',
    },
    {
      icon: <FaRecycle />,
      text: 'Our paper bags are <span style="color: #2e8b57;"><strong>eco-friendly</strong></span>, appealing to environmentally conscious consumers and enhancing your brand’s reputation. This sustainable approach not only helps the planet but also resonates with today’s eco-aware customers.',
    },
    {
      icon: <FaRocket />,
      text: 'As customers reuse your branded paper bags, your brand stays top-of-mind, encouraging <span style="color: #9400d3;"><strong>repeat business and fostering customer loyalty</strong></span>. This long-lasting impact extends the life of your marketing campaign beyond the initial distribution.',
    },
    {
      icon: <FaBullhorn />,
      text: 'Ready to attract more customers with our <span style="color: #8b0000;"><strong>innovative paper bag marketing?</strong></span> Contact us today to learn more and start designing your custom campaign.',
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          How Our Paper Bag Marketing Attracts Customers | [Your Brand Name]
        </title>
        <meta
          name="description"
          content="Discover how our innovative paper bag marketing strategy helps your brand reach a wider audience, increases customer loyalty, and enhances your brand's reputation with eco-friendly designs."
        />
        <meta
          name="keywords"
          content="paper bag marketing, eco-friendly packaging, brand loyalty, customer engagement, marketing strategy"
        />
        <meta
          property="og:title"
          content="How Our Paper Bag Marketing Attracts Customers"
        />
        <meta
          property="og:description"
          content="Learn about our unique paper bag marketing approach that boosts customer engagement and brand visibility."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="bigdoon.com" />
        <meta property="og:image" content="https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg" />
      </Helmet>

      <div
        className={`how-it-works-heading-container ${isMobile ? "mobile" : ""}`}
      >
        <h2 className="how-it-works-heading">
          How Our Paper Bag Marketing Attracts More Customers
        </h2>
      </div>
      <div className="how-it-works">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index % 2 === 0 ? "normal" : "reverse"}`}
          >
            <div className="icon-container">{step.icon}</div>
            <div
              className={`speech-bubble ${
                index % 2 === 0 ? "normal-bubble" : "reverse-bubble"
              }`}
            >
              <p dangerouslySetInnerHTML={{ __html: step.text }} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default HowItWorks;
