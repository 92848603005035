import React from "react";
import './legal.css'; // Import the CSS file
import { Helmet } from "react-helmet";

const Disclaimers = () => {
  return (
    <>
      <Helmet>
        <title>Disclaimers | BIGDOON</title>
        <meta
          name="description"
          content="Read the disclaimers for BIGDOON's website, covering external links, content accuracy, and limitation of liability."
        />
        <meta
          name="keywords"
          content="disclaimers, website disclaimer, external links, content accuracy, liability, BIGDOON"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://bigdoon.com/disclaimers" />
        <meta property="og:title" content="Disclaimers | BIGDOON" />
        <meta
          property="og:description"
          content="BIGDOON's disclaimers cover the accuracy of website content, liability limitations, and the usage of external links."
        />
        <meta
          property="og:url"
          content="https://bigdoon.com/disclaimers"
        />
        <meta
          property="og:image"
          content="https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg"
        />
      </Helmet>

      <div className="legal-container">
        <h1>Disclaimers</h1>
        <p>
          The information provided on our website is for general informational
          purposes only. We make no warranties or representations about the
          accuracy or completeness of the information.
        </p>
        <h2>External Links</h2>
        <p>
          Our website may contain links to third-party sites. We are not
          responsible for the content or practices of these external sites.
        </p>
        <h2>Content Accuracy</h2>
        <p>
          We strive to ensure that the content on our website is accurate and
          up-to-date. However, we do not guarantee the accuracy or reliability
          of any information provided.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
          We are not liable for any damages or losses arising from your use of
          our website or reliance on the information provided.
        </p>
        <h2>Changes to Disclaimers</h2>
        <p>
          We may update these disclaimers from time to time. Your continued use
          of our website constitutes acceptance of any changes.
        </p>
      </div>
    </>
  );
};

export default Disclaimers;
