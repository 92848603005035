import React from "react";
import "./legal.css"; // Create and style this CSS file as needed
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  return (
    <>
      <Helmet>
        <title>
          Shipping Policy | BIGDOON - Eco-Friendly Marketing Solutions
        </title>
        <meta
          name="description"
          content="Learn about BIGDOON's shipping policy for eco-friendly paper bags and other marketing products. Find details on delivery timelines, order tracking, and more."
        />
        <meta
          name="keywords"
          content="shipping policy, order tracking, BIGDOON, eco-friendly marketing, delivery timeline, paper bags, marketing products delivery"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="BIGDOON" />
        <meta
          property="og:title"
          content="Shipping Policy | BIGDOON - Eco-Friendly Marketing Solutions"
        />
        <meta
          property="og:description"
          content="Review BIGDOON's shipping policy. Get information on delivery timelines, tracking orders, and resolving delivery issues for marketing products."
        />
        <meta
          property="og:url"
          content="https://bigdoon.com/shipping-policy"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg"
        />
        <meta property="og:site_name" content="BIGDOON" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Shipping Policy | BIGDOON" />
        <meta
          name="twitter:description"
          content="Explore BIGDOON's shipping policy for eco-friendly marketing products. Learn about delivery timelines, tracking orders, and resolving issues."
        />
        <meta
          name="twitter:image"
          content="https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg"
        />
      </Helmet>
      <div className="legal-container">
        <h1>Shipping Policy</h1>

        <p>
          At <strong>BIGDOON</strong>. accessible from{" "}
          <a href="http://www.bigdoon.com">www.BIGDOON.com</a>, we are dedicated
          to delivering high-quality marketing products to our shop partners
          efficiently and on time. To enhance transparency and convenience, we
          provide a Track Order dashboard for tracking deliveries. Please review
          our shipping policy below:
        </p>

        <h2>
          <strong>1. Delivery of Marketing Products</strong>
        </h2>
        <p>
          We deliver marketing products, such as eco-friendly paper bags, to our
          shop partners, who then distribute them to their customers. The
          delivery timelines and tracking details are outlined below.
        </p>

        <h2>
          <strong>2. Minimum and Maximum Delivery Timeline</strong>
        </h2>
        <p>
          <strong>Minimum Delivery Timeline:</strong> The minimum delivery
          timeline for marketing products to shop partners is 7 business days
          from the date of order confirmation. This period allows us to prepare
          and dispatch the products promptly.
        </p>
        <p>
          <strong>Maximum Delivery Timeline:</strong> The maximum delivery
          timeline is 20 business days from the date of order confirmation. This
          timeframe accommodates production, quality checks, and shipping
          processes.
        </p>

        <h2>
          <strong>3. Tracking Deliveries</strong>
        </h2>
        <p>
          To provide our shop partners with visibility and convenience, we offer
          a Track Order dashboard where you can monitor the status of your
          delivery. You can access the dashboard using the tracking information
          provided in your order confirmation email.
        </p>

        <h2>
          <strong>4. How to Track Your Order</strong>
        </h2>
        <ul>
          <li>
            <strong>Log In:</strong> Access your account on our website.
          </li>
          <li>
            <strong>Track Order:</strong> Navigate to the Track Order section in
            your dashboard.
          </li>
          <li>
            <strong>Enter Details:</strong> Select your order to view the status
            and location of your delivery.
          </li>
        </ul>

        <h2>
          <strong>5. Delivery Issues</strong>
        </h2>
        <p>
          If you encounter any issues with the delivery of marketing products,
          such as damaged items or discrepancies, please contact us within 7
          days of receipt. We will investigate the issue and take appropriate
          action to resolve it.
        </p>

        <h2>
          <strong>6. Contact Information</strong>
        </h2>
        <p>
          If you have any questions or concerns about anything or related to our
          Shipping Policy, please contact us at:
        </p>
        <div className="legal-contact">
          <p>
            <strong>BIGDOON</strong>
            <br />
            <FaEnvelope /> Email:{" "}
            <a href="mailto:contact@BIGDOON.com">contact@BIGDOON.com</a>
            <br />
            <FaPhone /> Phone: <a href="tel:+919634830671">+919634830671</a>
          </p>
          <Link to="/contact" className="contact-link">
            Contact Us
          </Link>
        </div>

        <h2>
          <strong>7. Policy Changes</strong>
        </h2>
        <p>
          BIGDOON reserves the right to update or modify this shipping policy at
          any time. Any changes will be reflected on our website, and your
          continued use of our services constitutes acceptance of the updated
          policy.
        </p>
      </div>
    </>
  );
};

export default ShippingPolicy;
