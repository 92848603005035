import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './Footer.css';
import { RiTwitterXLine } from 'react-icons/ri';
import { ImFacebook2 } from 'react-icons/im';
import { TfiInstagram } from 'react-icons/tfi';
import logo from '../images/blacklogo.svg';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <footer className="footer">
            <div className="footer__content">
                <div className="footer__social">
                    <a href="https://www.facebook.com/profile.php?id=61566496836252" target="_blank" rel="noopener noreferrer" className="footer__social__icon">
                        <ImFacebook2 />
                    </a>
                    <a href="https://www.instagram.com/bigdoonmedia?igsh=MWI4cnA0cjNvcWNhNw==" target="_blank" rel="noopener noreferrer" className="footer__social__icon">
                        <TfiInstagram />
                    </a>
                    <a href="https://x.com/bigdoonmedia" target="_blank" rel="noopener noreferrer" className="footer__social__icon">
                        <RiTwitterXLine />
                    </a>
                </div>
                <div className="footer__links footer__nav">
                    <a onClick={() => handleNavigation('/')} style={{ cursor: 'pointer' }}>HOME</a>
                    <a onClick={() => handleNavigation('/about-us')} style={{ cursor: 'pointer' }}>ABOUT US</a>
                    <a onClick={() => handleNavigation('/contact')} style={{ cursor: 'pointer' }}>CONTACT</a>
                    <a onClick={() => handleNavigation('/career')} style={{ cursor: 'pointer' }}>CAREER</a>
                    <a onClick={() => handleNavigation('/track-order')} style={{ cursor: 'pointer' }}>TRACK ORDER</a>
                </div>
                <div className="footer__links footer__links--legal">
                    {/* <p>LEGAL POLICIES</p> */}
                    <a onClick={() => handleNavigation('/privacy-policy')} style={{ cursor: 'pointer' }}>PRIVACY POLICY</a>
                    <a onClick={() => handleNavigation('/terms-and-conditions')} style={{ cursor: 'pointer' }}>TERMS & CONDITIONS</a>
                    <a onClick={() => handleNavigation('/refund-policy')} style={{ cursor: 'pointer' }}>REFUND POLICY</a>
                    <a onClick={() => handleNavigation('/shipping-policy')} style={{ cursor: 'pointer' }}>SHIPPING POLICY</a>
                </div>

                <p className="footer__text__made__with__love">
                    Crafted with <span className="heart">❤️</span> in India
                </p>
                <div className="footer__logo">
                    <img src={logo} alt="Company Logo" />
                </div>
                <p className="footer__text">© {new Date().getFullYear()} All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
