import React from 'react';
import TypingEffect from './TypingEffect'; // Import the TypingEffect component
import '../css/TopHeading.css';
import { Helmet } from 'react-helmet';

const TopHeading = () => {
    const textList = [
        { text: "OOutdoor marketing", color: "#0C831F" },
        { text: "IInnovative campaigns", color: "#0C831F" },
        { text: "BBrand visibility", color: "#0C831F" },
        { text: "EEngaging strategies", color: "#0C831F" }
    ];

    return (
        <div className='top-heading-container'>
            <Helmet>
                <title>Outdoor Marketing Strategies | BIGDOON</title>
                <meta name="description" content="Discover powerful outdoor marketing strategies designed to maximize your brand's visibility and engagement. Learn how our innovative campaigns can captivate your target audience." />
                <meta name="keywords" content="outdoor marketing, innovative campaigns, brand visibility, engaging strategies" />
                <meta property="og:title" content="Outdoor Marketing Strategies" />
                <meta property="og:description" content="Explore innovative outdoor marketing strategies that enhance your brand visibility and engage your target audience." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="bigdoon.com/" />
                <meta property="og:image" content="https://bigdoon.com/static/media/blacklogo.31ff0e586d4206fe76a556dc5ca9f179.svg" />
            </Helmet>
            <h1>Let us show you the power of <TypingEffect textList={textList} speed={100} /></h1>
            <p>Maximize your brand's exposure and attract your target audience with our cutting-edge outdoor marketing strategies, crafted to captivate and engage.</p>
            {/* <div className='btn-container'>
                <div className="btn btn-one">
                    <span>REQUEST A QUOTE</span>
                </div>
            </div> */}
        </div>
    );
};

export default TopHeading;
