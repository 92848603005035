import React from 'react';
import './PaymentSuccess.css';
import { GiCheckMark } from 'react-icons/gi';
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {

  const navigate = useNavigate();

  return (
    <div className="payment-success-main-container">
    <div className="payment-success-container">
      <div className="payment-success-icon">
      <GiCheckMark />
      </div>
      <h1 className="payment-success-heading">Payment Successful</h1>
      <p className="payment-success-message">Your payment was processed successfully. Thank you for your purchase!</p>
      <button className="payment-success-button" onClick={()=>navigate("/track-order")}>Let's TRACK ORDER</button>
    </div>
    </div>
  );
};

export default PaymentSuccess;
