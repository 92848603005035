import React from "react";
import "./legal.css";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <div className="legal-container">
       <Helmet>
        <title>Terms and Conditions | BIGDOON - Eco-Friendly Marketing Solutions</title>
        <meta name="description" content="Read the Terms and Conditions for using BIGDOON's services, including our policies on user-generated content, intellectual property, and payment terms." />
        <meta name="keywords" content="Terms and Conditions, BIGDOON, legal, policies, user agreements, privacy" />
        <link rel="canonical" href="https://bigdoon.com/terms-and-conditions" />
      </Helmet>

      <h1>Terms & Conditions</h1>
      <p>
        <strong>Last updated:</strong> September 4, 2024
      </p>

      <h2>Introduction</h2>
      <p>
        Welcome to <strong>BIGDOON</strong>. accessible from
        <a href="https://www.bigdoon.com">www.BIGDOON.com</a>, you agree to
        comply with and be bound by the following Terms and Conditions, which,
        together with our Privacy Policy, govern BIGDOON's relationship with you
        in relation to this website. If you disagree with any part of these
        terms, please do not use our website.
      </p>

      <h2>1. Use of the Website</h2>
      <p>
        <strong>1.1.</strong> The content of this website is for your general
        information and use only. It is subject to change without notice.
      </p>
      <p>
        <strong>1.2.</strong> Unauthorized use of this website may give rise to
        a claim for damages and/or be a criminal offense under Indian law.
      </p>
      <p>
        <strong>1.3.</strong> You may not use our website in any way that is
        unlawful, fraudulent, or harmful, or in connection with any unlawful,
        fraudulent, or harmful purpose or activity.
      </p>

      <h2>2. Services Provided</h2>
      <p>
        <strong>2.1.</strong> BIGDOON offers outdoor marketing services,
        including the printing and distribution of eco-friendly paper bags to
        our shop partners.
      </p>
      <p>
        <strong>2.2.</strong> These bags are branded with marketing content as
        specified by our clients and are distributed to partner shops, which use
        them to provide groceries and other goods to their customers.
      </p>
      <p>
        <strong>2.3.</strong> Our services aim to promote brands in an
        eco-friendly manner while supporting a plastic-free future.
      </p>

      <h2>3. Intellectual Property Rights</h2>
      <p>
        <strong>3.1.</strong> This website contains material that is owned by or
        licensed to us. This material includes, but is not limited to, the
        design, layout, look, appearance, graphics, and content. Reproduction is
        prohibited without our express written consent.
      </p>
      <p>
        <strong>3.2.</strong> All trademarks reproduced on this website, which
        are not the property of or licensed to us, are acknowledged on the
        website.
      </p>

      <h2>4. User-Generated Content</h2>
      <p>
        <strong>4.1.</strong> By submitting any content to our website, you
        grant BIGDOON a non-exclusive, worldwide, irrevocable, royalty-free
        license to use, reproduce, adapt, publish, translate, and distribute
        such content in any existing or future media.
      </p>
      <p>
        <strong>4.2.</strong> Content submitted must not be illegal, unlawful,
        or infringe on any third party's legal rights. We reserve the right to
        remove any content submitted to our website at our discretion.
      </p>

      <h2>5. Limitation of Liability</h2>
      <p>
        <strong>5.1.</strong> BIGDOON will not be liable for any loss or damage,
        including without limitation, indirect or consequential loss or damage,
        or any loss or damage whatsoever arising from the use of or in
        connection with the use of this website.
      </p>
      <p>
        <strong>5.2.</strong> We do not warrant that the website will be
        uninterrupted or error-free, that defects will be corrected, or that the
        site or server that makes it available is free of viruses or bugs.
      </p>

      <h2>6. Links to Other Websites</h2>
      <p>
        <strong>6.1.</strong> This website may include links to other websites.
        These links are provided for your convenience to provide further
        information. They do not signify that we endorse the website(s). We have
        no responsibility for the content of the linked website(s).
      </p>

      <h2>7. Privacy Policy</h2>
      <p>
        <strong>7.1.</strong> Your use of this website is also subject to our
        Privacy Policy, which covers how we collect, use, store, and disclose
        your personal information. Please review our Privacy Policy to
        understand our practices.
      </p>

      <h2>8. Changes to Terms and Conditions</h2>
      <p>
        <strong>8.1.</strong> We reserve the right to modify these Terms and
        Conditions at any time. Changes will be effective immediately upon being
        posted on this website. Your continued use of the website following the
        posting of changes will constitute your acceptance of such changes.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        <strong>9.1.</strong> These Terms and Conditions are governed by and
        construed in accordance with the laws of India. Any disputes relating to
        these terms and conditions will be subject to the exclusive jurisdiction
        of the courts of India.
      </p>

      <h2>10. Payment Terms</h2>
      <p>
        <strong>10.1.</strong> All payments for services or products provided by
        BIGDOON are to be made in Indian Rupees (INR) using the payment methods
        specified on our website.
      </p>
      <p>
        <strong>10.2.</strong> You are responsible for any applicable taxes or
        fees.
      </p>
      <p>
        <strong>10.3.</strong> Refunds will be provided in accordance with our
        refund policy, which can be reviewed
        <a href="https://www.bigdoon.com/refund-policy">here</a>.
      </p>

      <h2>11. Return and Refund Policy</h2>
      <p>
        <strong>11.1.</strong> Our return and refund policy is applicable for a
        specified period from the date of purchase. Products must be unused,
        undamaged, and in original packaging.
      </p>
      <p>
        <strong>11.2.</strong> Services rendered are non-refundable unless
        otherwise stated.
      </p>
      <p>
        <strong>11.3.</strong> For more details, please refer to our
        <a href="https://www.bigdoon.com/refund-policy">
          Return and Refund Policy
        </a>
        .
      </p>

      <h2>12. Termination of Access</h2>
      <p>
        <strong>12.1.</strong> We reserve the right to terminate or suspend your
        access to our website or services at any time, without prior notice, if
        we believe you have violated these Terms and Conditions or engaged in
        any unlawful conduct.
      </p>

      <h2>13. Indemnity</h2>
      <p>
        <strong>13.1.</strong> You agree to indemnify and hold harmless BIGDOON,
        its affiliates, employees, and agents from any claims, damages, losses,
        liabilities, costs, or expenses (including legal fees) arising out of or
        related to your use of the website or breach of these Terms and
        Conditions.
      </p>

      <h2>14. Cookies and Tracking</h2>

      <h3>14.1. Use of Cookies and Tracking Technologies</h3>
      <p>
        Our website uses cookies and similar tracking technologies to enhance
        your browsing experience, provide personalized content, and analyze
        website traffic. By continuing to use our website, you consent to our
        use of cookies and tracking technologies as described in this policy.
      </p>

      <h3>14.2. What Are Cookies?</h3>
      <p>
        Cookies are small text files that are stored on your device (computer,
        tablet, or smartphone) when you visit a website. They help the website
        remember your actions and preferences (such as login, language, and
        other settings) over a period of time, so you don’t have to re-enter
        them whenever you return to the site.
      </p>

      <h3>14.3. Types of Cookies We Use</h3>
      <p>We use the following types of cookies on our website:</p>
      <ul>
        <li>
          <strong>Essential Cookies:</strong> These are necessary for the proper
          functioning of our website. They enable you to navigate the site and
          use its features, such as accessing secure areas or making a purchase.
        </li>
        <li>
          <strong>Performance and Analytics Cookies:</strong> These cookies
          collect information about how visitors use our website, such as which
          pages are most frequently visited and any errors that users encounter.
          The information is used to improve how our website works.
        </li>
        <li>
          <strong>Functionality Cookies:</strong> These cookies allow the
          website to remember your preferences and provide enhanced features.
          For example, they may store your location, username, or other
          preferences to provide a more personalized experience.
        </li>
        <li>
          <strong>Targeting and Advertising Cookies:</strong> These cookies are
          used to deliver content or advertisements that are more relevant to
          you and your interests. They also help limit the number of times you
          see an ad and measure the effectiveness of advertising campaigns.
        </li>
      </ul>

      <h3>14.4. How We Use Cookies</h3>
      <p>We use cookies for various purposes, including:</p>
      <ul>
        <li>
          <strong>To improve website performance:</strong> Cookies help us
          monitor the performance of our website and identify areas that need
          improvement.
        </li>
        <li>
          <strong>To provide a personalized experience:</strong> By remembering
          your preferences and interactions with our website, we can tailor
          content and features to better suit your needs.
        </li>
        <li>
          <strong>To analyze website traffic:</strong> We use cookies to collect
          data on how users interact with our website, such as which pages are
          visited most often and how long users stay on the site. This helps us
          understand user behavior and improve our content.
        </li>
        <li>
          <strong>To enable online transactions:</strong> Some cookies are
          essential for enabling purchases, remembering items in your cart, and
          processing payments.
        </li>
      </ul>

      <h3>14.5. Third-Party Cookies</h3>
      <p>
        We may also allow third-party service providers, such as advertising
        partners or analytics providers, to place cookies on your device. These
        cookies are managed by the third parties and are subject to their
        respective privacy policies. We use third-party cookies for purposes
        such as:
      </p>
      <ul>
        <li>
          <strong>Analytics:</strong> To monitor and analyze website traffic and
          usage patterns through tools like Google Analytics.
        </li>
        <li>
          <strong>Advertising:</strong> To display personalized advertisements
          based on your browsing habits.
        </li>
      </ul>

      <h3>14.6. How to Manage Cookies</h3>
      <p>
        You can control and manage cookies in several ways. Most browsers allow
        you to refuse cookies or delete them after they have been placed on your
        device. Please note that disabling cookies may affect the functionality
        of our website and limit your ability to use certain features.
      </p>
      <ul>
        <li>
          <strong>Browser Settings:</strong> You can adjust your browser
          settings to block or delete cookies. Each browser is different, so
          refer to your browser’s help section for specific instructions.
        </li>
        <li>
          <strong>Opting Out of Third-Party Cookies:</strong> Some third-party
          advertisers allow you to opt out of targeted advertising through their
          own privacy policies or industry opt-out mechanisms.
        </li>
      </ul>

      <h3>14.7. Changes to Our Cookies Policy</h3>
      <p>
        We may update this Cookies and Tracking Policy from time to time to
        reflect changes in technology, legislation, or our operations. Any
        changes will be posted on this page, and we encourage you to review it
        periodically to stay informed.
      </p>

      <p>
        By using our website, you agree to the terms outlined in this Cookies
        and Tracking Policy. If you have any questions or concerns about our use
        of cookies, please feel free to <a href="https://bigdoon.com/contact">contact us</a>.
      </p>

      <h2>15. Force Majeure</h2>
      <p>
        <strong>15.1.</strong> BIGDOON shall not be liable for any failure to
        perform its obligations under these Terms and Conditions if such failure
        is caused by circumstances beyond its reasonable control, including but
        not limited to acts of God, war, strikes, or interruptions in
        telecommunications.
      </p>

      <h2>16. Disclaimer of Warranties</h2>
      <p>
        <strong>16.1.</strong> The website and its content are provided on an
        "as is" basis, without any warranties of any kind, either express or
        implied, including but not limited to warranties of merchantability,
        fitness for a particular purpose, or non-infringement.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about anything or related to our
        Terms & Conditions, please contact us at:
      </p>
      <div className="legal-contact">
        <p>
          <strong>BIGDOON</strong>
          <br />
          <FaEnvelope /> Email:
          <a href="mailto:contact@BIGDOON.com">contact@BIGDOON.com</a>
          <br />
          <FaPhone /> Phone:
          <a href="tel:+919634830671">+919634830671</a>
        </p>
        <Link to="/contact" className="contact-link">
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default TermsAndConditions;
